(function (window, document, undefined) {
  var httpRequest = new XMLHttpRequest();

  httpRequest.addEventListener('load', onHttpResponse);
  httpRequest.open('get', '/public/images/svg-icons.svg', true);
  httpRequest.send();

  function onHttpResponse(response) {
    var div = document.createElement('div');

    div.innerHTML = httpRequest.responseText;
    div.classList.add('svg-sprite-sheet');

    document.body.appendChild(div);
  }
})(window, document)
